import { z } from 'zod';

import { entityValidator } from './entityValidator';

export const ObjectTypeArray = ['object', 'otherObject'] as const;

export const ZObjectWrite = z.object({
  data: z
    .object({
      name: z.string().optional(),
      type: z.enum(ObjectTypeArray).optional(),
      overview: z.boolean().optional(),
    })
    .optional(),
  parent: z.string().uuid().nullable().optional(),
});

export const objectValidator = z.intersection(
  z.intersection(entityValidator, ZObjectWrite),
  z.object({}),
);
