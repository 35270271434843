import { useContext } from 'react';
import type { ReactNode } from 'react';
import { AuthContext, AuthProvider } from 'react-oauth2-code-pkce';
import type { TAuthConfig, TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce';

import { useConfig } from '../hooks/useConfig';

const redirectUri = `${window.location.origin}/callback`;

// eslint-disable-next-line
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an DevoteamAuthProvider');
  }

  if (context.tokenData && new Date(context.tokenData.exp * 1000) < new Date()) {
    return { ...context, token: undefined, tokenData: undefined };
  }

  return context;
};

const DevoteamAuthProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading } = useConfig();

  if (!data || isLoading) {
    return <div>Loading</div>;
  }

  const { cognitoClientId, cognitoSigninUrl } = data;

  const authConfig: TAuthConfig = {
    clientId: cognitoClientId,
    authorizationEndpoint: `${cognitoSigninUrl}/login`,
    tokenEndpoint: `${cognitoSigninUrl}/oauth2/token`,
    redirectUri,
    autoLogin: false,
    clearURL: false,
    scope: 'openid',
    onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
      window.confirm('Session expired. Refresh page to continue using the site?') && event.login(),
  };

  return <AuthProvider authConfig={authConfig}>{children}</AuthProvider>;
};

export default DevoteamAuthProvider;
