import { z } from 'zod';

import { userRoleValidator } from './userRoleValidator';

export const cognitoUserValidator = z.object({
  userId: z.string(),
  userName: z.string(),
});

export const whoamiValidator = z.object({
  user: cognitoUserValidator,
  roles: z.array(userRoleValidator),
});
