import ky from 'ky';
import type { Whoami } from 'api';

export const whoamiApi = (apiClient: typeof ky) => ({
  getWhoami: async () =>
    apiClient
      .get('whoami')
      .json<Whoami>()
      .then((res) => {
        const userRoles = res?.roles.map((r) => r.role);
        return {
          whoami: res,
          userRoles,
          isAdmin: userRoles?.includes('admin'),
          hasMultipleRoles: new Set(userRoles).size > 1,
        };
      }),
});
