import { useMemo } from 'react';
import ky from 'ky';

import { api } from '../api';
import { useAuth } from '../contexts/auth';

const prefixUrl = '/api';

type ApiMethod = keyof ReturnType<typeof api>;

export const useApi = <T extends ApiMethod>(method: T) => {
  const { idToken } = useAuth();

  const apiClient = useMemo(
    () =>
      ky.create({
        prefixUrl,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }),
    [idToken],
  );

  return api(apiClient)[method];
};
