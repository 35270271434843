import { useAuth } from './contexts/auth';
import { useWhoami } from './hooks/useWhoami';
import { useObjects } from './hooks/useObjects';

export const App = () => {
  const { logOut, login, tokenData } = useAuth();
  const { data: whoami } = useWhoami();
  const { data: objects } = useObjects();

  return (
    <>
      <div>
        <h1>Express + React + Typescript + Terraform + Neon Postgresql + AWS</h1>
        {tokenData ? (
          <>
            <p>Current object data:</p>
            <pre>
              <code>{JSON.stringify(objects, null, 4)}</code>
            </pre>
            <p>Current user data:</p>
            <pre>
              <code>{JSON.stringify(whoami, null, 4)}</code>
            </pre>
            <button onClick={() => logOut()}>logout</button>
          </>
        ) : (
          <button onClick={() => login()}>login</button>
        )}
      </div>
    </>
  );
};
