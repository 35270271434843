import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../contexts/auth';

import { useApi } from './useApi';

export const useWhoami = () => {
  const { token } = useAuth();
  const getWhoami = useApi('getWhoami');
  return useQuery(['whoami'], getWhoami, {
    staleTime: Infinity,
    enabled: !!token,
  });
};
