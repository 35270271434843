import { z } from 'zod';

export const roleValidator = z.enum(['admin', 'readonly']);

export const userRoleWriteValidator = z.object({
  userId: z.string(),
  object: z.string().uuid(),
  role: roleValidator,
});

export const userRoleValidator = z.intersection(
  userRoleWriteValidator,
  z.object({
    id: z.string().uuid(),
  }),
);

export const userRoleCreateValidator = z.object({
  email: z.string(),
  username: z.string(),
  object: z.string().uuid(),
  role: roleValidator,
});
