import { createBrowserRouter, Navigate, useSearchParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { useAuth } from '../contexts/auth';
import { App } from '../App';

// eslint-disable-next-line
const CallbackHandler = () => {
  const { loginInProgress } = useAuth();
  const [searchParams] = useSearchParams();
  const redirectURL = searchParams.get('state') || '/';
  if (loginInProgress) {
    return <div>Loading</div>;
  }
  return <Navigate replace to={redirectURL} />;
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    errorElement: <div>Error!</div>,
    children: [
      { index: true, path: '/', element: <App /> },
      { path: '/callback', element: <CallbackHandler /> },
    ],
  },
]);
