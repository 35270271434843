import { z } from 'zod';

import { roleValidator } from './userRoleValidator';

export const userCreateValidator = z.object({
  email: z.string(),
  username: z.string(),
  object: z.string().uuid(),
  role: roleValidator,
});

export const userWriteValidator = z.object({
  email: z.string(),
  username: z.string(),
});

export const userValidator = z.intersection(
  userWriteValidator,
  z.object({
    id: z.string().uuid(),
  }),
);
