import { z } from 'zod';

export const entityValidator = z.object({
  id: z.string().uuid(),
  created: z
    .string()
    .datetime({ offset: true })
    .or(z.date().transform((val) => val.toISOString())),
  updated: z
    .string()
    .datetime({ offset: true })
    .or(z.date().transform((val) => val.toISOString())),
});
