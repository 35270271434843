import ky from 'ky';
import { z } from 'zod';

import { objectValidator } from 'api';

export const objectApi = (apiClient: typeof ky) => ({
  getObjects: async () => {
    const response = await apiClient.get('objects').json();
    return z.array(objectValidator).parse(response);
  },
});
