import ky from 'ky';

import { objectApi } from './object';
import { whoamiApi } from './whoami';

export const api = (apiClient: typeof ky) =>
  Object.freeze({
    ...objectApi(apiClient),
    ...whoamiApi(apiClient),
  });
